export const customers = [
  {
    src: require("../assets/img/company logos/jsw-paints-logo.min.png"),
    alt: "Jsw paints",
  },
  {
    src: require("../assets/img/company logos/meritor-h-v-s-india-ltd.min.jpg"),
    alt: "Meritor HVS India Pvt Ltd",
  },
  {
    src: require("../assets/img/company logos/Filtrec-S.p.A.-logo.min.png"),
    alt: "Filtrec Bharat Mfg Pvt Ltd",
  },
  {
    src: require("../assets/img/company logos/rpcl.min.png"),
    alt: "RPCL",
  },
  {
    src: require("../assets/img/company logos/bmm.min.png"),
    alt: "BMM",
  },
  {
    src: require("../assets/img/company logos/BergerLogo.min.png"),
    alt: "Berger Paints",
  },
  {
    src: require("../img/company logos/skfBearing.avif"),
    alt: "SKF Bearing",
  },
  {
    src: require("../assets/img/company logos/mangalorechemicals.min.jpeg"),
    alt: "Mangalore Chemicals and Fertilizers",
  },
  {
    src: require("../assets/img/company logos/kern_liebers.min.png"),
    alt: "Kern Liebers India",
  },
  {
    src: require("../assets/img/company logos/lehryValves.min.png"),
    alt: "Lehry Valves",
  },
  {
    src: require("../assets/img/company logos/AUTOAXLES.min.png"),
    alt: "Automotive Axles Ltd.",
  },
  {
    src: require("../assets/img/company logos/vijaynagarSugars.min.png"),
    alt: "Vijaynagar Sugars Pvt. Ltd.",
  },
  {
    src: require("../assets/img/company logos/EIDParrys.min.jpeg"),
    alt: "EID Parry India Ltd",
  },
  {
    src: require("../assets/img/company logos/coromandel.min.png"),
    alt: "Coromandel",
  },
  {
    src: require("../assets/img/company logos/jindalSaw.min.png"),
    alt: "Jindal Saw Ltd",
  },
  {
    src: require("../assets/img/company logos/renew_power_logo.min.png"),
    alt: "Renew Power",
  },
  {
    src: require("../assets/img/company logos/Hindlaco.min.jpeg"),
    alt: "Hindlaco",
  },
  {
    src: require("../assets/img/company logos/chowgule_lavgan.min.jpeg"),
    alt: "Chowgule Lavgan",
  },
  {
    src: require("../assets/img/company logos/Chettinad_Cement.min.jpeg"),
    alt: "chettinadu_cement",
  },
  {
    src: require("../assets/img/company logos/harita-fehrer.min.png"),
    alt: "harita-fehrer",
  },
  { src: require("../assets/img/company logos/ITC.min.png"), alt: "ITC" },
  {
    src: require("../assets/img/company logos/Minda_Industries_Ltd.min.png"),
    alt: "Minda_Industries_Ltd",
  },
  {
    src: require("../assets/img/company logos/minera-steel-power-pvt-ltd.min.jpeg"),
    alt: "minera-steel-power-pvt-ltd",
  },
  {
    src: require("../assets/img/company logos/Murugappa_Group.min.png"),
    alt: "Murugappa Group",
  },
  {
    src: require("../assets/img/company logos/ttk_prestige.min.png"),
    alt: "ttk_prestige",
  },
  { src: require("../assets/img/company logos/TVS.min.jpg"), alt: "TVS" },
  {
    src: require("../assets/img/company logos/Sagar_Cement.min.png"),
    alt: "Sagar_Cement",
  },
  {
    src: require("../assets/img/company logos/sterlingandwilson.min.png"),
    alt: "Sterling and Wilson",
  },
  {
    src: require("../assets/img/company logos/godrej_agrovet.min.jpeg"),
    alt: "Godrej Agrovet Ltd.",
  },
];
