export default function WhatsappIcon() {
  return (
    <>
      <a href="https://api.whatsapp.com/send?phone=919986078746">
        <img
          className="whatsAppIcon"
          src="/icons8-whatsapp.svg"
          alt="whatsappicon"
        />
      </a>
    </>
  );
}
