import { PRODUCTS } from "./data";
const keys = Object.keys(PRODUCTS);
export const menu = [
  { title: "Lockout Safety Padlock", url: keys[0] },
  { title: "Lockout Safety Tags", url: keys[1] },
  { title: "Lockout Hasps", url: keys[2] },
  { title: "Electrical Lockout", url: keys[3] },
  { title: "Mechanical Lockout", url: keys[4] },
  { title: "Industrial Safety Items", url: keys[5] },
  { title: "Safety Posters", url: keys[6] },
];

